footer {
  color: #818181;
  background: #fff;
  border-top: 1px solid #e5e4e7;
  font-size: 0.93em;
  font-weight: 400;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  .footer-content {
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 27px;
  }


  .footer-content .footer-logo > a > img {
    width: 118px;
    height: 26px;
    opacity: .6;
  }


  .footer-content .footer-logo > div {
    font-size: 10px;
    padding-left: 3px;
  }


  .footer-content ._am_core-links {
    padding-top: 12px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }


  .footer-content ._am_core-links a {
    color: #818181;
    margin-left: 20px;
    text-decoration: none;
    font-weight: bold;
  }

}


